import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { RichText } from "prismic-reactjs"
import Sponsors from "../components/sponsors"

const Heading = styled.h1`
  padding: 0 1rem;
`
const A = styled.a`
  padding: 0 1rem;
`
const Html = styled.div`
  padding: 0 1rem;
  a {
    color: #6594d4;
  }
`
const Academy = styled.div`
  div {
    padding: 0 1rem;
    display: grid;
    grid-column-gap: 2em;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "heading1 heading2"
      "content1 content2";
  }
  h1 {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  p {
  }
  h1:nth-child(1) {
    grid-area: heading1;
  }
  p:nth-child(2) {
    grid-area: content1;
  }
  h1:nth-child(3) {
    grid-area: heading2;
  }
  p:nth-child(4) {
    grid-area: content2;
    margin-top: 0;
  }
  a {
    color: black;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
  }
  a:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 800px) {
    div {
      grid-template-columns: 1fr;

      grid-template-areas:
        "heading1"
        "content1"
        "heading2"
        "content2";
    }
    h1 {
      width: 100%;
    }
    p {
      font-size: 1rem;

      column-count: 1;
      width: 100%;
    }
  }
`

class Kontakt extends React.Component {
  render() {
    console.log(this.props)
    const data = this.props.data.kontakt.allKontakts.edges[0].node
    const { academy } = this.props.data.about.allHomepage2020s.edges[0].node
    return (
      <Layout location={this.props.location.pathname}>
        <SEO title="Kontakt" />
        <Heading>{data.title[0].text}</Heading>
        <Html>{RichText.render(data.description)}</Html>
        <Academy id="academy">{RichText.render(academy)}</Academy>
        <Sponsors lang="de-at" />
      </Layout>
    )
  }
}
export default Kontakt

export const pageQuery = graphql`
  query KontaktQuery {
    about: prismic {
      allHomepage2020s(lang: "de-at") {
        edges {
          node {
            academy
          }
        }
      }
    }
    kontakt: prismic {
      allKontakts(lang: "de-at") {
        edges {
          node {
            description
            thumbnail
            title
            _meta {
              id
            }
          }
        }
      }
    }
  }
`
